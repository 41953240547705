/*box shadow*/

:root {
    --primary-color: #F77200;
    --secondary-color: #2052bd;
    --light-orange: #f3d7c0;
    --light-coral: #E37B7B;
    --orange-brown: #B96928;
    --dark-orange: #f36c00;
    --dark-red: #B92828;
    --orange-shadow: #c95c03;
    --light-green: #c4e4d570;
    --black: #000000;
    --gray: #D9D9D9;
    --dark-gray: #808080;
    --red: #FF0000;
    --yellow: #FFFF00;
    --green: #069306;
    --error-color: #d32f2f;
    --white: #FFFFFF;
    --text-color: #3b3434de;
    --shadow-color: #1a1a1a;
}

  
.box-shadow {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1)
}

.date-picker{
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
    outline: 0;
    transform-origin: top center;
}

.MuiBox-root .css-zxdg2z{
    max-width: 100vw!important;
    overflow: hidden;
}
.css-w2mm8c-MuiGrid-root {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100vw!important;
}
/* font family */
.font-Nunito {
    font-family: 'Nunito';
}

.dark {
    color: #000000 !important;
}


.br-1 {
    border-radius: 4px !important;
}

.br-2 {
    border-radius: 8px !important;
}

.cp {
    cursor: pointer;
}

.mapBackground {
    background-image: url('../images/mapImage/IWS_Stall_2023_v1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* font size */
.fs-2 {
    font-size: 2px;
}

.fs-4 {
    font-size: 4px;
}

.fs-6 {
    font-size: 6px;
}

.fs-8 {
    font-size: 8px;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-22 {
    font-size: 22px;
}

.fs-24 {
    font-size: 24px;
}


/* font-weight */

.fw-600 {
    font-weight: 600;
}

/* color */
.orange-color {
    color: #FF7200;
}

/* background color */

.orange-bg {
    background: #FF7200;
}

/* text styling */

.center-text {
    text-align: center;
}

/* remove scroll bar */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

/* .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 4px 6px 9px 2px !important;
} */

.UM_date-input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 5px 10px 6px 11px !important;
}

/* .UM_date-input .css-m4m26z-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 5px 10px 6px 11px !important;
    height: 35px !important;
} */

/* .UM_date-input .css-ghfxo2-MuiFormLabel-root-MuiInputLabel-root {
    transform: translate(7px, 7px) scale(1) !important;
} */

.UM_pagination .css-miumh3-MuiTablePagination-displayedRows {
    margin-top: 15px !important;
}

/* .UM_pagination .css-miumh3-MuiTablePagination-displayedRows{
    margin-top: 15px !important;
} */

.UM_pagination .MuiTablePagination-toolbar {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
}

/*  */

.UM_addnew-btn:hover {
    border: 1px solid #FF7200 !important;
}

.UM_export-btn:hover {
    border: 1px solid black !important;
}

.UM_delete-btn {
    background-color: black !important;
    color: white !important;
}

.UM_delete-btn:disabled {
    background: gray !important;
    border: 1px solid gray !important;
    color: rgb(204, 204, 204) !important;
}

.UM_uploadfile-btn:hover {
    background: #FF7200 !important;
}

.UM_uploadfile-btn:disabled {
    background: gray !important;
    border: 1px solid gray !important;
    color: rgb(204, 204, 204) !important;
}


.UM_uploadfile-btn {
    color: white !important;
    border: 1px solid #FF7200 !important;
    background: #FF7200 !important;
}

.UM_outline-btn {
    color: #455A64 !important;
    border: 1px solid #455A64;
    outline-color: #455A64 !important;
}

.UM_outline-btn:hover {
    border: 2px solid #FF7200;
    color: #FF7200 !important;
}

.UM_outline-btn:focus {
    color: #FF7200 !important;
    border: 1px solid #FF7200 !important;
}

.UM_fileupload-input {
    width: 227px;
    height: 34px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding-left: 2px;
    padding-top: 1px;
}

.UM_fileupload-input:focus {
    border: 1px solid #FF7200 !important;
    outline: 1px solid #FF7200 !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {

    border-radius: 10px !important;
}

.css-22jxwj-MuiPaper-root-MuiDialog-paper {
    border-radius: 8px !important;
}

/*Grid css close*/

.grid-section {
    min-width: 100px !important;
    min-height: 100px;
    overflow-y: auto;
    /* display: flex; */
}

.grid-section table,
.grid-section td,
.grid-section th {
    border: 1px solid black;
}

.grid-section table {
    width: 100%;
    border-collapse: collapse;
}

.grid-section td {
    text-align: center;
    vertical-align: middle;
    padding: 4px;
    /* width: 45px; */
    /* height: 45px; */
}

.grid-section td div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.css-18erg0n-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 6px !important;
    font-size: .8rem !important;

}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.MuiCheckbox-root.Mui-checked {
    color: #FF7200 !important;
}



#printArea {
    margin-left: 5px,

}

.css-1c5ij41 {
    margin: 13px !important;
}

.simpletable table {
    width: 100%;
}

.simpletable table,
.simpletable td,
.simpletable th {
    border: 1px solid #595959;
    border-collapse: collapse;
}

.simpletable td,
.simpletable th {
    padding: 3px;
    width: 75px;
    height: 75px;
}

.simpletable th {
    background: #f0e6cc;
}

.even {
    background: #fbf8f0;
}

.odd {
    background: #fefcf9;
}

#barDiv {
    width: 100%;
}

#barDiv svg {
    width: 100% !important;
}

@media print {
    .single-check {
        page-break-inside: avoid;
        page-break-before: always;
        font-family: "Arial", sans-serif;
        font-weight: 900;
        /* padding-bottom: 140px!important; */
    }
}

@page {
    /* size: A4; */
    margin-top: 20px;
    margin-bottom: 20px;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    max-height: 400px ! important;

}
.css-1u10lj0-MuiPaper-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    max-width: 100%!important;
    /* max-width: 1260px; */
    overflow-x: auto;
}
@media print {
    .single-QR-page {
      page-break-inside: avoid;
      page-break-before: always;
      /* padding-bottom: 140px!important; */
    }
  
  }
  
  @page {
    /* size: A4; */
    /* width: 302.36px;
    height: 170.08ox; */
    margin-top: 20px;
    margin-bottom: 20px;
  }

/* .css-13yhac4-MuiTableCell-root {
    font-family: Nunito,serif;
    font-weight: 400;
    line-height: 1.43;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    padding: 5px!important;
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px!important;
    text-align: center;
    overflow-wrap: break-word;
} */

.report-dialog > .MuiDialog-container > .MuiDialog-paper {
    padding: 20px;
}

.MuiAutocomplete-tag {
    color: #FF7200;
}
.css-781ah-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
min-width: 0px !important;
}
.css-1v1brc9 .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 0px !important;
}

.copy,
.clear,
.paste {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
}

.copy-updn {
    display: flex;
    flex-direction: column;
}

.inside-grid {
    cursor: pointer;
    width: 35%;
    margin-left: 33%;
}
.hover-grid:hover {
    text-decoration: underline;
    font-weight: 800;
    color: green;
  }
