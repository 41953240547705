.css-pdct74-MuiTablePagination-selectLabel{
    margin-top: 12px !important;
}

.css-levciy-MuiTablePagination-displayedRows{
    margin-top: 14px;
}

/* .css-fikjyc-MuiTableCell-root-MuiTablePagination-root{
    padding-right: 3rem !important;
} */

.css-1ex1afd-MuiTableCell-root {
    border-right: 1px solid rgba(224, 224, 224, 1) !important;
  
}

.AM_pagination .css-mirfh3-MuiTablePagination-displayedRows{
    margin-top: 15px !important;
}

.AM_pagination .MuiTablePagination-toolbar{
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
}

.AreaMap_container .css-tbvl7h-MuiGrid-root>.MuiGrid-item {
    padding-top: 10px !important;
}

.view-btn:hover {
    background: #FF7200 !important;
}

.edit-btn:hover {
    background: black !important;
}

.AM-label-container {
    max-width: fit-content;
    min-height: 40px;
    border: 1px solid black;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
    background: #FFFFFF;
    border: 1px solid #FF7200;
    border-radius: 8px;
}


.AM-container {
    display: flex;
    margin: 2px 10px 2px 10px;
}

.AM-center {
    margin: auto;
}

.AM-green-box {
    width: 24px !important;
    height: 24px !important;
    background: green;
    padding: 0.5rem;
    border-radius: 2px;
}

.AM-yellow-box {
    width: 24px !important;
    height: 24px !important;
    background: yellow;
    padding: 0.5rem;
    border-radius: 2px;
}

.AM-red-box {
    width: 24px !important;
    height: 24px !important;
    background: #E9001A;
    padding: 0.5rem;
    border-radius: 2px;
}

.AM-white-box {
    width: 24px !important;
    height: 24px !important;
    background: #FFFFFF;
    padding: 0.5rem;
    border-radius: 2px;
    border: 1px solid black;
}


/* modal */
.curser {
    cursor: pointer;
}

.AM-header {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
}


.AM-blockHeader {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #FF7200;
}

.AM-overFlowAuto {
    overflow: auto;
}

.AM-textfield {
    /* border: 1px solid red !important; */
    height: 50px !important;
}
.AM-textfield ::placeholder{
    border: 1px solid red;
}

.AM-textfield label[data-shrink=false]+.MuiInputBase-formControl .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 35px;
}

.addAllocate-btn:hover {
    background: #FF7200 !important;
    color: white;
}

.addAllocate-btn:disabled{
    border: none;
}

.AllocateCancel-btn {
    color: #FF7200 !important;
    border: 1px solid #FF7200 !important;
}

.multi-select {
    height: 40px !important;
    margin-top: 8px !important;
    /* border: 1px solid red !important; */
}

.clip{
    border: 1px solid #D9D9D9;
    min-width: 50px;
    max-width: fit-content;
    height: 31px;
    border-radius: 20px;
    padding: 5px 8px 5px 8px;
    margin: 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #D9D9D9;
    /* color: white; */
    font-weight: 500;
}