
@font-face {
    font-family: GOTHIC;
    src: url('./../fonts/GOTHIC.TTF');
  }

.login-parent-div{
    width: 100%;
    height: 620px;
    display: flex;
} 

.login-parent-div img{
    width: 100%;
    height: 100%;
   position: absolute;

}

.login-left{
    width: 50%;
    height: 100%;

}

.login-right{
    width: 50%;
    height: 100%;
}
.login-div{
    width: 90%;
    height: 400px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    left: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.login-div span{
    color: #fff;
    font-size: 2.7rem;
    padding-left: 20px;
    font-weight: 600;
    font-family:GOTHIC ;
    
}
.login-form{
    width: 87%;
    height: 242px;
    position: relative;
    top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.login-field-1{
    width: 100%;
    height: 60px;
    display: flex;

}

.login-img{
    width: 10%;
    height: 100%;
   border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
   
}

#user_pic{
    font-size: 33px;
    color: white;
    
}

.email-div{
    width: 100%;
    height: 100%;
 
}
#input-field{
    width: 90%;
    height: 85%;
    position: relative;
    top: 5px;
    padding-left: 20px;
   border: 1px solid white;
   border-left: none;
   border-top-right-radius: 10px;
   border-bottom-right-radius: 10px;
   font-family:GOTHIC ;
}

::placeholder{color: white;
font-size: 1rem;}

.login-field-2{
    width: 100%;
    height: 60px;
    display: flex;
}
.lock-img{
    width: 10%;
    height: 100%;
   border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
#lock-pic{
    font-size: 33px;
    color: white;
    
}

.passwordDiv{
    width: 80%;
    height: 100%;
}
#input-field1{
    width: 100%;
    height: 85%;
    position: relative;
    top: 5px;
    padding-left: 20px;
  background-color: transparent;
   border: 1px solid white;
   border-left: none;
   border-top-right-radius: 10px;
   border-bottom-right-radius: 10px;
   color: #fff;
   letter-spacing: 2.1px;
   font-family:GOTHIC ;
}
input:focus {
    outline-width: 0;
}
#input-field2{
    width: 100%;
    height: 85%;
    position: relative;
    top: 5px;
    padding-left: 20px;
    background-color: transparent;
    border: 1px solid white;
    border-right: none;
    border-left: none;
    color: #fff;
   letter-spacing: 2.1px;
   font-family:GOTHIC ;
}
.eye-div{
    width: 10%;
    height: 85%;
    position: relative;
    top: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: none;
}
#show-eye{
    font-size: 17px;
    color: white;
    cursor: pointer;
}
.login-btn-group{
    width: 100%;
    height: 60px;
    display: flex;
}

.btn-div{
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.btn-div button{
    width:44%;
    height: 45px;
    border-radius: 18px;
    text-transform: uppercase;
    font-size: 14px;
    border: 1px solid white;
    background-color: transparent;
    color: white;
    cursor: pointer;
    font-family:GOTHIC ;
}

.btn-div button:hover{
    width:44%;
    height: 45px;
    border-radius: 18px;
    text-transform: uppercase;
    font-size: 14px;
    border: 1px solid #ff7200;
    background-color: #fff;
    color: #ff7200;
    cursor: pointer;
}

/* Recover modal pop-up */

.recover-moadal, .recover-overlay{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: block !important;
    z-index: 1;
    
}

.recover-overlay{
    background-color: rgba(49,49,49,0.8);
}

.recover-content{
    width: 30%;
    height: 300px;
    position: absolute;
    top: 350px;
    left: 50%;
    transform: translate(-50%,-50%);
    line-height: 1.1;
    background-color: #ff7200;
    /* border-radius: 10px;    */
    text-align: center;
    z-index: 2;
}

.recover_data{
    width: 97%;
    height: 96%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 7px;
   background-color: #fff;
   border-radius: 10px;
}
.inner-div{
    width: 100%;
    height: 90%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
 
}
.recover-heading{
    width: 100%;
    height: 80px;
    border: 1px solid silver;
    border-top-style: none;
    

}

#heading-style{
    position: absolute;
    left: 15px;
    top: 10px;
    font-size: 22px;
    color: #888;
    font-weight: 600;
    font-family:GOTHIC ;
    
}

#heading-cross{
    position: absolute;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
}

.recover-input{
    width: 100%;
    height: 110px;
    border: 1px solid silver;
    border-top-style: none;
    font-family:GOTHIC ;
    
}

.recover-button{
    width: 100%;
    height: 80px;
    /* background-color: bisque; */
    font-family:GOTHIC ;
}

.recover-input-field{
    width: 73%;
    height: 37px;
    border-radius: 7px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family:GOTHIC ;
    
}

.recover-input-field input{
    width: 100%;
    height: 33px;
    border-radius: 7px;
    border: 1px solid silver;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding-left: 12px;
    font-family:GOTHIC ;
   
   letter-spacing: 1px;
   font-family:GOTHIC ;
}
.recover-input-field ::placeholder{
    color: #888;
    font-size: 13px;
}

.btn-element{
    width: 90%;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 18px;
    display: flex;
    flex-direction: column;
}

.btn-element button{
    width: 55%;
    height: 35px;
    border-radius:16px ;
    border: none;
    background-color: #ff7200;
    color: white;
    font-size: 14px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    font-family:GOTHIC ;
  
}

.btn-element button:hover{
    width: 55%;
    height: 35px;
    border-radius:16px ;
    border: 1px solid #ff7200;
    background-color: #fff;
    color: #ff7200;
    font-size: 14px;
    cursor: pointer;
}

/* otp modal css */

.otp-input{
    width: 65%;
    height: 60px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    justify-content: space-around;
}

.input-1, .input-2, .input-3, .input-4{
    width: 20%;
    height: 60px;
    font-family:GOTHIC ;
}

.otp-field{
    width: 100%;
    height: 100%;
   /* border: none; */
   border: 1px solid silver;
    box-shadow:  0.2px 0.2px 6px grey inset ;
    text-align: center;
    font-size: 33px;

}

.resend{
    font-size: 13px;
    position: relative;
    top: 8px;
    color: #888;
    font-family:GOTHIC ;
   
}
#resend-style{
    font-size: 13px;
    color: #ff7200;
    position: relative;
    cursor: pointer;  
  
}

/* password modal css */


.password-moadal, .password-overlay{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: block !important;
    z-index: 1;
    
}

.password-overlay{
    background-color: rgba(49,49,49,0.8);
}

.password-content{
    width: 34%;
    height: 330px;
    position: absolute;
    top: 350px;
    left: 50%;
    transform: translate(-50%,-50%);
    line-height: 1.1;
    background-color: #ff7200;
    /* border-radius: 10px;    */
    text-align: center;
    z-index: 2;
}

.password_data{
    width: 97%;
    height: 96%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 7px;
    background-color: #fff;
    border-radius: 10px;
}
.password-div{
    width: 100%;
    height: 90%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
}
.recover-pass-input{
    width: 100%;
    height: 150px;
    border: 1px solid silver;
    border-top-style: none;
    font-family:GOTHIC ;
}
.pass-inner-div{
    width: 74%;
    height: 120px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.pass-top-div{
    width: 85%;
    height: 36px;
    border: 2px solid silver;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    display: flex;
}
.new-pass{
    width: 90%;
    height: 100%;
}
.new-pass input{
    width: 100%;
    height: 100%;
    border: none;
    padding-left: 12px;
    letter-spacing: 1px;
    font-family:GOTHIC ;
}
.requirements{
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#info{
    color: #888;
    cursor: pointer;
    
}
#green-info{
    color: green;
    cursor: pointer;
}
.pass-bottom-div{
    width: 85%;
    height: 36px;
    border: 2px solid silver;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    display: flex;
}

.confirm-pass{
    width: 90%;
    height: 100%;
    background-color: #ff7200; 
}
.confirm-pass input{
    width: 100%;
    height: 100%;
    border: none;
    padding-left: 12px;
    letter-spacing: 1px;
    font-family:GOTHIC ;
}
.right-tick{
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.new-pass ::placeholder,.confirm-pass ::placeholder{
    color: #888;
    font-size: 13px;
    padding-left: 12px;
}


/* Password ploicies modal */


.policy-moadal{
    width: 100vw;
    height: 10vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;    
    z-index: 1;
}

.recover-overlay{
    background-color: rgba(49,49,49,0.8);
}

.policies-content{
    width: 25%;
    height: 220px;
    position: absolute;
    top: 380px;
    left: 81%;
    transform: translate(-50%,-50%);
    line-height: 1.1;
    background-color: #fff;
    border-radius: 14px;   
    text-align: center;
    z-index: 2;
}
.policies-div{
   width: 99%;
   height: 98%;
   position: relative;
   top: 2px;
   border-radius: 14px;  
   margin-left: auto;
   margin-right: auto;
   background-color: #ff7200;   
}
.policies_list{
    width: 97%;
    height: 94%;
    position: relative;
    top: 7px;
    border-radius: 14px;  
    margin-left: auto;
    margin-right: auto;
   background-color: aliceblue;
   display: flex;
}
.policies_list ul{
    width: 100%;
    height: 100%;
    display: inline-block;
    margin:17px 3px;  
    text-align:left;
    font-family:GOTHIC ;
}
.list-1{
    font-size: 14px;
    margin-bottom: 10px;
}
.green-list{
    font-size: 14px;
    margin-bottom: 10px;
    color: green;
    font-weight: 600;
  
}
#policy-cross{
    position: absolute;
    right: 6px;
    font-size: 24px;
    cursor: pointer;
  
}
#verify{
    color: green;
    border: 1px solid green;
    border-radius: 50%;
}

#hide{display: none !important;}

.bg-new{
    position: relative;
    display: inline-block;
    /* height: 100vw; */
    /* height: 892px; */
    overflow: hidden;
    margin: 0;


    background: url("https://whitelabling.s3.ap-south-1.amazonaws.com/demox.teksmartsolutions.com/loginBg.png");
    /* background: url("../images/login/background.jpg"); */
    /* scale: 0.7; */
    background-size: cover;
    /* background-size: 1024px;
    background-repeat: no-repeat; */
}

.bg-image{
    position: relative;
    display: inline-block;
    /* height: 100vw; */
    /* height: 892px; */
    overflow: hidden;
    margin: 0;


    /* background: url("https://whitelabling.s3.ap-south-1.amazonaws.com/demox.teksmartsolutions.com/loginBg.png"); */
    background: url("../images/login/background.jpg");
    /* scale: 0.7; */
    background-size: cover;
    /* background-size: 1024px;
    background-repeat: no-repeat; */
    
}

.mui-custom-card {
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25) !important;
    border-radius: 12px;    
}

.mui-custom-input {
    padding-left: 5%;
    line-height: 22px;
    width: 100%;
    height: 48px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}

.mui-custom-button {
    width: 100%;
    line-height: 15px;
    height: 38px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px !important;
    background-color: #FF7200 !important;
    color: white !important;
}



































