
/* .muitable table thead tr>td:nth-child(1) {
	width: 20px !important;
}
.muitable table thead tr>td:nth-child(2) {
	width: 30px !important;
}
.muitable table thead tr>td:nth-child(n+3) {
	width: 140px !important;
} */
/* 
.muitable table {
	width: 100%;
	overflow: auto;
	border-radius: 12px !important;
} */

.muitable{
	max-width: 100vw!important;
	/* max-width: 95vw!important; */
} 

/* .muitable table,
.muitable table tr {
	border: 1px solid rgb(231 233 235);
	border-collapse: collapse;
} */

.muitable table th {
	/* text-align: center;
	font-size: 14px;
	color: rgb(0, 0, 0);
	background-color: #fafafa;
	font-weight: 700;
	color: #242338 !important;
	font-size: 1rem; */
	padding: 0px 5px;
	white-space: nowrap !important;
}

.muitable table td {
	/* text-align: center;
	font-size: 14px;
	font-weight: 400;
	font-size: .9rem; */
	padding: 7px;
	white-space: nowrap !important;

}
/* 
.muitable table tr {
	width: 100% !important;
}

.muitable .table-row:hover {
	background-color: #fbece3;
	opacity: .7;
	transition: all .5s;
} */
