/* Change selected date background and text color */
.custom-orange-date-picker .rmdp-selected {
    background-color: #f77200; /* Orange background */
    color: #ffffff; /* White text */
    border-radius: 50%; /* Round selected date */
  }
  
  /* Change hover color */
  .custom-orange-date-picker .rmdp-day:hover {
    background-color: #ffa040; /* Light orange hover */
    color: #ffffff; /* White text on hover */
  }
  
  /* Change focus border color and size */
  .custom-orange-date-picker .rmdp-wrapper:focus-within {
    border: 2px solid #f77200; /* Orange border on focus */
    border-radius: 12px;
    outline: none;
  }
  
  /* Change the border color and size on hover */
  .custom-orange-date-picker .rmdp-wrapper:hover {
    border: 2px solid #ffa040; /* Light orange border */
    border-radius: 12px;
  }
  
  /* Custom styling for the date panel */
  .custom-orange-date-picker .rmdp-panel {
    background-color: #fff6e6; /* Light orange background for panel */
    color: #455a64; /* Text color for panel */
  }
  
  /* Custom text color in the date picker input */
  .custom-orange-date-picker input {
    color: #f77200; /* Orange text color */
    font-size: 14px;
    border: 1px solid #d9d9d9; /* Default border */
    padding: 8px;
    border-radius: 8px;
  }
  
  /* Change border color of input on focus */
  .custom-orange-date-picker input:focus {
    border: 2px solid #f77200; /* Orange border on focus */
    outline: none;
  }
  