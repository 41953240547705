.GridView_container .css-tbvl7h-MuiGrid-root>.MuiGrid-item {
    padding-top: 10px !important;
}

/* .GV_select{
    border: 2px solid gold !important;
    border-radius: inherit !important;
}

.Mui-focused .GV_select {
    border: 2px solid gold !important;
} */



.GV-title-container {
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
}

.GV-label-container {
    max-width: fit-content;
    min-height: 40px;
    border: 1px solid black;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #FF7200;
    border-radius: 8px;
}


.GV-container {
    display: flex;
    margin: 2px 10px 2px 10px;
}

.GV-center {
    margin: auto;
}

.GV-green-box {
    width: 24px !important;
    height: 24px !important;
    background: green;
    padding: 0.5rem;
    border-radius: 2px;
}

.GV-yellow-box {
    width: 24px !important;
    height: 24px !important;
    background: yellow;
    padding: 0.5rem;
    border-radius: 2px;
}

.GV-red-box {
    width: 24px !important;
    height: 24px !important;
    background: #E9001A;
    padding: 0.5rem;
    border-radius: 2px;
}

.GV-white-box {
    width: 24px !important;
    height: 24px !important;
    background: #FFFFFF;
    padding: 0.5rem;
    border-radius: 2px;
    border: 1px solid black;
}

/* modal */
.warehouseTable-no_data_found{
    border: 1px solid gray;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-top: 10px;
    font: 500;
    font-size: 14px;
    font-family: 'Nunito';
}
.curser {
    cursor: pointer;
}

.header {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 33px;
    color: #000000;
}


.blockHeader {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 33px;
    color: #FF7200;
}

.overFlowAuto {
    overflow: auto;
}

.addRack-btn:hover {
    background: #FF7200 !important;
}

.rackCancel-btn {
    color: #FF7200 !important;
    border: 1px solid #FF7200 !important;
}

.warehouseTableWrapper {
    max-width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.dttable {
    width: 100%;
}

.dttable,
.dttable tr,
.dttable td,
.dttable th {
    border: 1px solid #727376;
    border-collapse: collapse;
    padding: 5px 10px;
    text-align: center;
}

.MuiTablePagination-displayedRows{
    margin-top: 10px;
}
