.mui-orange-btn,
.mui-orange-btn:hover {
    /* max-height: "32px"; */
    background-color: #FF7200 !important;
    color: white;
}


.mui-black-btn,
.mui-black-btn:hover {
    background-color: black !important;
    color: white;
}

.mui-drawer-item {
    text-decoration: none;
}

.mui-custom-input {
    font-size: 10px;
}

.mui-custom-input::placeholder {
    font-size: 10px;
}

.priyanshuScollable {
    height: 175px !important;
    overflow: hidden;
}

.priyanshuScroll {
    overflow: scroll !important;
    overflow-x: hidden !important;
}

.priyanshuCheckBox {
    width: 95%;
    height: auto;
    margin: 7px;
}

.priyanshuMainCheck {
    width: 100%;
    position: relative;
    bottom: 5px;
    color: #000;
    font-weight: 500;
    margin: 2px;
}

.priyanshucheckheading {
    font-size: 16px;
    color: #000;
    text-decoration: underline;
    text-underline-offset: 3px;
}

.priyanshuTwoContainer {
    width: 85%;
    padding-left: 16px;
    margin: auto;
}

.priyanshuCheckText {
    font-size: 14px;
    padding-left: 30px;
    font-weight: 500;
}

.priyanshuCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.priCardHead {
    margin: 0;
    font-family: Nunito, serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.8;
    padding-left: 15px;
    font-weight: 700;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    margin: 32px;
    position: relative;
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: calc(100% - 64px);
    max-width: 600px;
    padding: 17px;
    justify-content: center;
    text-align: center;
}

.pShowMore {
    color: #ff7200;
    position: absolute;
    bottom: 0px;
}

/* .priyanshuScroll::-webkit-scrollbar {
    width: 3px;
} */

/* Track */
/* .priyanshuScroll::-webkit-scrollbar-track {
    background: #FFFFFF;
} */

/* Handle */
/* .priyanshuScroll::-webkit-scrollbar-thumb {
    background: #808080;
} */

body::-webkit-scrollbar {
    width: 0.3em!important;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}
.clHeader{
    width: 97%;
    margin: auto;
    height: auto;
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

}
.pOuterParent{
    width: 100%;
    height: auto;
}
.pInnerTop{
    width: 100%;
    height: 252px;
}
.pInnerBody{
    width: 100%;
    height: 150px;
}
.pAlignCent{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pjustifySBtw{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.pInnerBottom{
    width: 80%;
    height: 300px;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: auto;
    margin-top: 25px;
}
.pCard{
    width: 45%;
    height: "auto";
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 3px 2px #D3D3D3;
}
.pCardTop{
    width: 92%;
    margin: auto;
}
.pCardBody{
    width: 92%;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.cardPoints{
    color: #000;
    font-size: 14px;
    font-weight: 400;
}
.pcardOuter{
    width: 100%;
    height: 300px;
    /* border:1px solid red; */
}
.customCard{
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 16px;
    display: flex;
    height: auto;
    justify-content: space-between;
    
}
.customCardLeft{
    width: 76%;
    height: auto;
    border-radius: 8px;
    padding-bottom: 15px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 3px 5px #D9D9D9;

}
.customCardRight{
    width: 23%;
    height: auto;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 3px 5px #D9D9D9;
}
.pcardContainer{
    width: 97%;
    /* height: 57vh; */
    margin: auto;
    margin-top: 15px;
    border-radius: 8px;
    padding: 6px;
    box-shadow: 0px 3px 5px #D9D9D9;
}
.pcardO{
    /* height: 218px; */
    height: 'auto';
    overflow: hidden;
}
.pcardCTop{
    width: 100%;
    height: auto;
    padding: 10px;
}
.workflowName{
    font-size: 14px;
    font-weight: 700;
}
.operationText{
    font-size: 12px;
    font-weight: 400;
    padding-left: 10px;
}
.pcardCBody{
    width: 98%;
    height: 'auto';
    margin: '2px';
    display: flex;
    padding: '5px';
    margin-top: 12px;
    flex-wrap: wrap;
    /* max-height: 160px; */
  overflow-y: auto;
    border-bottom: 1px solid #D9D9D9;
}
.pcardCBodyNew{
    width: 98%;
    height: 48vh;
    /* max-height: 180px; */
    margin: auto;
    display: flex;
    align-items: center;
    border-bottom: none;
    position: relative;
    overflow: hidden;
}
.pBoxVertical{
    width: auto;
    display: flex;
    height: 35px;
}
.pcardScroll{
    height: 20px;
    color: #FF7200;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 3px;
    padding-left: 5px;
    font-size: 14px;
}
.pcardBoyChange{
    height: auto;
    display: flex;
    margin-top: 0px;
    flex-wrap: wrap;
    border-bottom: none;
}
.pcardCHeader{
    width: 98%;
    margin: auto;
    margin-top: 12px;
}

.closeIcon{
    font-size: 40px;
    color: red;
    font-size: 20px;
    font-weight: 900;
    position: relative;
    cursor: pointer;
    right: -7px;
    bottom: 2px;
    z-index: 100;
    font-weight: 800;
    transform: scale(0.8);
}

.dropZone{
    width: 170px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #E0E0E0 ;
    color: #E0E0E0;
    margin-bottom: 10px;
    border-radius: 6px;
}

.draggable-item {
    background-color: #f0f0f0;
    padding: 10px;
    margin-bottom: 10px;
    cursor: move;
  }
.draggable-item:hover {
    background-color: #e0e0e0;
}
.ccontaoner{
    width: 94%;
    height: 100%;
    display: flex;
    margin: auto;
    padding: 4px;
}
.cardP{
    width: 100%;
    margin: auto;
    height: 100%;
    align-items: center;
    display: flex;
    overflow: hidden;
    white-space: normal;
}

.viewCard{
    width: 31%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #ff7200;
}
.constantCard{
    width: 23%;
    height: 100%;
    border-radius: 8px;
    position: relative;
    border: 1px solid #ff7200;
}
.viewCardHeader{
    width: 100%;
    height:12%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #ff7200;
}
.viewHeading{
    color: #fff;
    padding-left: 6px;
    font-size: 12px;
}
.myIcon{
    color: #fff;
    cursor: pointer;
    transform: scale(0.7);
}
.viewCradBody{
    width: 100%;
    height: 86%;
    padding: 6px;
    overflow-y:auto;
    overflow-x: hidden;
}
#asasa{
    width: 20%;
    background-color: red !important;
}
.viewCardArrow{
    display: flex;
    justify-content:center ;
    align-items: center;
    color: #ff7200;
    transform: scale(1);
    width: 3%;
    height: auto;
}
.crChanges{
    border: 1px solid #ff7200;
    background-color: #FFE3CC;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cRConstant{
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
}
.modelHead{
    display: flex;
    justify-content: space-between;
}
.modalBody{
    width: 100%;
    display: flex;
}
.tempBox {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 15px;
    border: 1px solid #0000002B;
    box-shadow: 0px 4px 4px 0px #00000040;
    margin-top: 28px;
    width: 100%;
    padding: 15px;
}
.crossIconPri{
    display: inline-block;
    justify-content: flex-end;
    align-items: end;
    position: absolute;
    right: 0px;
    cursor: pointer;
}



/* .MuiOutlinedInput-root {
    height: 36px !important;
    font-size: 0.8rem !important;
} */

.customDatePicker .MuiSvgIcon-root{
    font-size: 1.1rem !important;
}
.tabsClass {
    font-weight: 800;
    color: #ff7200;
}
.customDatePicker .MuiFormLabel-root{ 
    font-size: 0.85rem !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.35);
}
.MuiInputBase-input{
    font-size: 0.85rem !important;
    font-weight: 500 !important;
    color: #000 !important;
}
.customSelectt{
    color: #ff7200 !important;
    border: 0px solid #fff !important;
    box-shadow: 0px 0px 0px white !important;
}
.customSelectt>.MuiOutlinedInput-notchedOutline{
    border-color: #fff !important;
}
.customSelectt>.MuiSelect-icon{
    color: #ff7200 !important;
}
.customSelectt>.MuiSelect-icon{
    color: #ff7200 !important;
}
.customSelectt>.MuiInputBase-input{
    color: #ff7200 !important;
}

/* MuiSvgIcon-root  */
