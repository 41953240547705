/* Styling for the selected range background */
.custom-range-picker .rmdp-range {
    background-color: #FF7200 !important; /* Orange color for selected range */
    color: white; /* White text for better visibility */
}

/* Arrow button color */
.custom-range-picker .rmdp-arrow-container button {
    color: #FF7200; /* Orange color for arrow buttons */
}

/* Hover effect for day cells */
.rmdp-day:hover {
    background-color: #FF9000 !important; /* Lighter orange for day hover effect */
}

/* Optional border for hover on days (not in range or disabled) */
.rmdp-day:not(.rmdp-range):not(.rmdp-disabled):hover {
    border: 1px solid #FF7200; /* Orange border for hovered days */
}

/* Styling for the weekday labels */
.rmdp-week-day {
    color: #FF7200 !important; /* Orange color for weekday labels (e.g., Mon, Tue, etc.) */
}

/* Styling for selected days */
.rmdp-selected {
    background-color: #FF7200 !important; /* Orange color for selected days */
    color: white !important; /* White text color for selected days */
}

/* Styling for panel items (e.g., month or year selectors) */
.rmdp-panel-body li {
    background-color: #FF7200 !important; /* Orange background for panel items */
}

/* Styling for deselect buttons inside the panel */
.rmdp-panel-body li .b-deselect {
    background-color: #FF7200 !important; /* Orange background for deselect buttons */
}

/* Styling for the arrow icon border */
.rmdp-arrow {
    border-color: #FF7200 !important; /* Orange border for the arrow icon */
}

/* Hover effect for the arrow container */
.rmdp-arrow-container:hover {
    background-color: #FFD580 !important; /* Light orange hover effect for arrow container */
}

/* Styling for today's date and selected dates */
.custom-range-picker .rmdp-day.rmdp-selected span,
.custom-range-picker .rmdp-day.rmdp-today span {
    background-color: gray; /* Gray background for today and selected dates */
    color: white; /* White text for better visibility */
    border-radius: 50%; /* Rounded appearance for the background */
}

/* Hover effect for day cells that are not disabled or hidden */
.custom-range-picker .rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover {
    background-color: #FF9000 !important; /* Lighter orange for day hover effect */
}

/* Styling for the range being hovered (during selection) */
.rmdp-range-hover {
    background-color: #FFD580 !important; /* Light orange background for range hover */
}
