/* body {
    scrollbar-width: thin;
} */

.roundedRow :first-child {
    border-top-left-radius: "12px";
}
.roundedRow :last-child {
    border-top-right-radius: "12px";
}
.MuiOutlinedInput-root{
    border-radius: 12px !important;
}

.MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #FF7200!important
}

@media all {
    .page-break {
      display: none;
    }
  }
  
/* @media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
} */

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@page {
    size: auto;
    margin: 20mm;
}